body {
  /* background: #000000;
  background-image: url(./assets/beomken\ I.jpeg);
  background-repeat:no-repeat;
  background-size:contain; */


 margin:0px;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section{
  /* background-image: url(./assets/beomken\ II.jpeg); */


  /* background-repeat:no-repeat;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-size:cover;
  background-position:center;

  margin:0; */

  /* height: 100vh;
    width: auto; */



    background: #000000;
  background-image: url(./assets/beomken\ I.jpeg);
  background-repeat:no-repeat;
  background-size:100% 100%;
  background-position: center center;
  width: auto;
  height: 100vh;

}


section{
  display: flex;
  justify-content: center;
  align-items: center;
}


/*
.MuiContainer-root {
   width: 0px !important;
   padding:20% !important;

} */


/* .makeStyles-done-3 {
  color: white;
  margin: 8px;
  display: flex;
  padding: 8px;
  font-size: 10px !important;
  background: #384457;
  align-items: center;
  font-weight: bold;
  margin-right: 0;
  align-content: center;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
} */


.makeStyles-done-3 {
  color: white;
  /* margin: 8px; */
  display: flex;
  padding: 1% !important;
  font-size: 1vw !important;
  background: #384457;
  align-items: center;
  font-weight: bold;
  margin-right: 0;
  align-content: center;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
}


#cta:hover {
  color : white !important;
  background-color: #50492C;
  /* color: black !important; */
}


#social-links {
  /* display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  align-content:flex-start; */
}

#icons
{
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  align-content: center;


}


i:hover {
  color :  #932919 !important;



  /* color: black !important; */
}


@media only screen and (max-width: 600px) {
  .makeStyles-done-3{
    background-color: #384457;
  }
}
